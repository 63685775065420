var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: 16 } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        mode: "multiple",
                        showArrow: false,
                        "not-found-content": null,
                        "filter-option": false,
                        placeholder: "请输入人员昵称",
                        "option-filter-prop": "label",
                      },
                      on: {
                        search: _vm.userSearch,
                        change: _vm.searchQueryForm,
                      },
                      model: {
                        value: _vm.userIds,
                        callback: function ($$v) {
                          _vm.userIds = $$v
                        },
                        expression: "userIds",
                      },
                    },
                    _vm._l(_vm.searchUsers, function (user) {
                      return _c(
                        "a-select-option",
                        { key: user.userId, attrs: { label: user.userName } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(user.userName) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "search" },
                      on: { click: _vm.searchQueryForm },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "cloud" },
              on: { click: _vm.upImg },
            },
            [_vm._v("上传")]
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: (row) => row.img.id,
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
          scroll: { x: "1200px", y: "calc(100vh - 405px)" },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u(
          [
            {
              key: "imgUrl",
              fn: function (text, record) {
                return _c("span", {}, [
                  _c("div", { staticClass: "img-div" }, [
                    record.img
                      ? _c("img", { attrs: { src: record.img.url, alt: "" } })
                      : _vm._e(),
                  ]),
                ])
              },
            },
            {
              key: "imgTitle",
              fn: function (text, record) {
                return _c("span", {}, [
                  _c("span", [_vm._v(_vm._s(record.img && record.img.title))]),
                ])
              },
            },
            {
              key: "time",
              fn: function (text, record) {
                return _c("span", {}, [
                  _c("span", [
                    _vm._v(
                      _vm._s(record.img && record.img.enableStart.split(" ")[0])
                    ),
                  ]),
                ])
              },
            },
            {
              key: "realname",
              fn: function (text, record) {
                return _c("span", {}, [
                  _c("img", {
                    staticStyle: {
                      width: "30px",
                      height: "30px",
                      "border-radius": "50%",
                      "margin-right": "5px",
                      position: "relative",
                      top: "-2px",
                    },
                    attrs: { src: record.upper.avatar, alt: "" },
                  }),
                  _c("span", [
                    _vm._v(_vm._s(record.upper && record.upper.realname)),
                  ]),
                ])
              },
            },
            {
              key: "good",
              fn: function (text, record) {
                return _c("span", {}, [
                  _c("span", [_vm._v(_vm._s(record.count.good || "0"))]),
                ])
              },
            },
            {
              key: "action",
              fn: function (text, record) {
                return !record.children
                  ? _c("span", {}, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleimg(record)
                            },
                          },
                        },
                        [_vm._v(" 删除")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-left": "15px" },
                          on: {
                            click: function ($event) {
                              return _vm.editImg(record)
                            },
                          },
                        },
                        [_vm._v(" 编辑")]
                      ),
                    ])
                  : _vm._e()
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }