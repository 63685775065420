<template>
  <body-layout>
    <div class="table-page-search-wrapper" slot="search">
      <a-row type="flex" :gutter="16">
        <a-col>
          <a-select
            mode="multiple"
            :showArrow="false"
            :not-found-content="null"
            :filter-option="false"
            placeholder="请输入人员昵称"
            option-filter-prop="label"
            v-model="userIds"
            style="width: 200px"
            @search="userSearch"
            @change="searchQueryForm"
          >
            <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId">
              {{ user.userName }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-button type="primary" icon="search" @click="searchQueryForm">搜索</a-button>
        </a-col>
      </a-row>
    </div>
    <div class="actions flex" slot="actions">
      <a-button type="danger" @click="batchDel" class="mr-3" v-if="selectedRowKeys.length" icon="delete">删除</a-button>
      <a-button type="primary" @click="upImg" icon="cloud">上传</a-button>
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      ref="table"
      size="middle"
      :rowKey="row => row.img.id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="ipagination"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
      :scroll="{ x: '1200px', y: 'calc(100vh - 405px)' }"
    >
      <span slot="imgUrl" slot-scope="text, record">
        <div class="img-div">
          <img v-if="record.img" :src="record.img.url" style="" alt="" />
        </div>
      </span>
      <span slot="imgTitle" slot-scope="text, record">
        <span>{{ record.img && record.img.title }}</span>
      </span>
      <span slot="time" slot-scope="text, record">
        <span>{{ record.img && record.img.enableStart.split(' ')[0] }}</span>
      </span>
      <span slot="realname" slot-scope="text, record">
        <img
          :src="record.upper.avatar"
          style="width:30px;height:30px;border-radius: 50%;margin-right:5px;position:relative;top:-2px"
          alt=""
        /><span>{{ record.upper && record.upper.realname }}</span>
      </span>
      <span slot="good" slot-scope="text, record">
        <span>{{ record.count.good || '0' }}</span>
      </span>
      <span slot="action" slot-scope="text, record" v-if="!record.children">
        <a @click="deleimg(record)"> 删除</a>
        <a style="margin-left:15px;" @click="editImg(record)"> 编辑</a>
      </span>
    </a-table>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { getAction, deleteAction } from '@/api/manage'
export default {
  mixins: [JeecgListMixin],
  data() {
    return {
      userIds: [],
      searchUsers: [],
      columns: [
        {
          title: '图片',
          dataIndex: 'imgUrl',
          scopedSlots: { customRender: 'imgUrl' },
          width: 300
        },
        {
          title: '标题',
          dataIndex: 'imgTitle',
          scopedSlots: { customRender: 'imgTitle' },
          width: 300
        },
        {
          title: '上传时间',
          scopedSlots: { customRender: 'time' },
          dataIndex: 'time',
          width: 220
        },
        {
          title: '发布人',
          scopedSlots: { customRender: 'realname' },
          dataIndex: 'realname'
        },
        {
          title: '点赞数量',
          scopedSlots: { customRender: 'good' },
          sorter: true,
          dataIndex: 'good'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          dataIndex: 'action'
        }
      ],
      url: {
        list: '/imgCenter/page/manage',
        delete: '/imgCenter',
        deleteBatch: '/imgCenter'
      },
      allUsers: []
    }
  },
  created() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      getAction('/sys/sysDepart/userListToSelect').then(res => {
        this.allUsers = Object.assign([], res.data)
      })
    },
    handleTableChange(pagination, filters, sorter) {
      if(sorter.order=='descend'){
        this.queryParam.sortByGoodCountDesc=true
      }else if(sorter.order=='ascend'){
        this.queryParam.sortByGoodCountDesc=false
      }else{
        delete this.queryParam.sortByGoodCountDesc
      }
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.savePageSize2LS(this.url.list, pagination.pageSize)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    userSearch(value) {
      if (value) {
        console.log(this.allUsers)
        this.searchUsers = [...this.searchUsers, ...this.allUsers.filter(item => item.userName.indexOf(value) > -1)]
        this.searchUsers = [...new Set(this.searchUsers)]
      } else {
        this.searchUsers = []
      }
    },
    deleimg(record) {
      let that = this
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/imgCenter', { ids: [record.img.id].join(',') })
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
                that.onClearSelected()
                that.$emit('loadData')
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    editImg(record) {
      this.$emit('editImg', record)
    },
    upImg() {
      this.$emit('addImg')
    },
    searchQueryForm() {
      this.queryParam.creatorIds = this.userIds.join(',')
      this.loadData(1)
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}
.img-div {
  width: 140px;
  height: 105px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: auto;
  }
}
</style>
